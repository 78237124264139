import { $fetch } from "ofetch";
import { ref } from "vue";
import { watchDebounced } from "@vueuse/core";
import { EventTypes } from "~/constants";

export type RequestProduct = {
    productId: number;
    versionId?: number;
    finish?: string;
    notes?: string;
};
export type Request = {
    products: RequestProduct[];
    country?: string;
    region?: string;
    notes?: string;
    client?: string;
    listino?: number;
};

const emptyState = {
    products: [],
    country: "",
    region: "",
    notes: "",
    client: "",
    listino: 0,
};

export default function useProductRequest() {
    const { user } = useUserSession();
    const { logEvent } = useEventLog();
    const { listino } = usePermissions();
    const { $t } = useLanguages();

    const requests = ref(
        // @ts-expect-error
        user.value?.requests || { ...emptyState }
    ) as Ref<Request>;

    if (!requests.value?.products) {
        requests.value.products = [];
    }

    watchDebounced(
        requests,
        (newRequest) => {
            // @ts-expect-error
            user.value.requests = newRequest;

            $fetch("/api/user/request/save", {
                method: "POST",
                body: { ...newRequest, listino: listino.value },
            });
        },
        {
            debounce: 500,
            maxWait: 1000,
            deep: true,
        }
    );

    async function resetRequests() {
        requests.value.products = [];
        requests.value.notes = "";
        requests.value.client = "";
    }

    function addProductToRequest(product: RequestProduct) {
        if (requests.value) {
            {
                requests.value.products.push(product);
                alert($t("request.msg_model_added"));
            }
        }
    }

    function removeProductFromRequest(product: RequestProduct, silent = false) {
        if (requests.value) {
            {
                const idx = requests.value.products.findIndex(
                    (version: any) =>
                        version.productId === product.productId &&
                        version.versionId === product.versionId &&
                        version.finish === product.finish
                );
                if (idx > -1) {
                    requests.value.products.splice(idx, 1);
                    if (!silent) {
                        alert($t("request.msg_model_removed"));
                    }
                }
            }
        }
    }

    function isModelInRequest(product: RequestProduct) {
        return (
            requests.value?.products?.some(
                (version: any) =>
                    version.productId === product.productId &&
                    version.versionId === product.versionId &&
                    version.finish === product.finish
            ) == true
        );
    }

    async function sendRequest() {
        if (!requests.value.country || !requests.value.region) {
            alert($t("request.msg_insert_country_region"));
            return false;
        }

        await $fetch("/api/user/request/send", {
            method: "POST",
        });

        logEvent(EventTypes.CHECKOUT, {
            request: requests.value,
        });

        await resetRequests();

        alert($t("request.msg_request_sent"));
        return true;
    }

    const requestsCount = computed(() => {
        return requests.value?.products?.length || 0;
    });

    return {
        requests,
        addProductToRequest,
        removeProductFromRequest,
        isModelInRequest,
        sendRequest,
        requestsCount,
    };
}
